import React from "react";
import "./orderError.css";

const OrderError = ({ isError, setError }) => {
  return (
    <div className={!isError ? "error__container" : "error__container setted"}>
      <div className="accept__block">
        <h1 className="error__text">Виникла помилка у з'єднанні</h1>
        <h1 className="error__text">Спробуйте ще раз</h1>
        <span className="error__logo">
          <i className="bi bi-x-circle-fill"></i>
        </span>
        <button className="error__btn" onClick={() => setError(false)}>
          Повернутись до замовлення
        </button>
      </div>
    </div>
  );
};

export default OrderError;
