import React, { useState } from "react";
import "./menuNav.css";
import PropTypes from "prop-types";

const MenuNav = ({ categories, onCategorySelect, selectedCategory }) => {
  const [activeFirstCategory, setActiveFirstCategory] = useState(true);

  return (
    <div className="categories__menu">
      <ul className="categories__list">
        {categories.map((category) => (
          <li
            className="categories__list-item"
            key={category._id + 1234}
            onClick={() => onCategorySelect(category)}>
            {!category._id ? (
              <button
                className={
                  activeFirstCategory
                    ? "btn categories__list-button-active"
                    : "btn categories__list-button"
                }>
                {category.name}
              </button>
            ) : (
              <button
                className={
                  "btn categories__list-button" +
                  (selectedCategory && category._id === selectedCategory._id
                    ? "-active"
                    : "")
                }
                onClick={() => setActiveFirstCategory(false)}>
                {category.name}
              </button>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

MenuNav.propTypes = {
  categories: PropTypes.array.isRequired,
  onCategorySelect: PropTypes.func.isRequired,
};

export default MenuNav;
