import React from "react";
import { Link } from "react-router-dom";
import "./orderAccepted.css";

const OrderAccepted = ({ orderAccepted, handleCloseWindow }) => {
  return (
    <div
      className={
        !orderAccepted ? "accepted__container" : "accepted__container setted"
      }>
      <div className="accept__block">
        <h1 className="accepted__text">Замовлення прийнято!</h1>
        <h1 className="accepted__text">Незабаром ми до вас зателефонуємо</h1>
        <span className="accepted__logo">
          <i className="bi bi-check-circle-fill"></i>
        </span>
        <Link to="/">
          <button className="accepted__btn" onClick={handleCloseWindow}>
            На головну
          </button>
        </Link>
      </div>
    </div>
  );
};

export default OrderAccepted;
