import { useState, useEffect } from "react";

const UseApiNew = (url) => {
  const [apiData, setApiData] = useState([]);
  const [apiError, setApiError] = useState("");
  const [apiLoading, setApiLoading] = useState(false);

  const fetchAsync = async (url) => {
    setApiLoading(true);
    try {
      let response = await fetch(url);
      let data = await response.json();
      setApiData(data);
    } catch (error) {
      setApiError(error);
    } finally {
      setApiLoading(false);
    }
  };

  useEffect(() => {
    fetchAsync(url);
  }, [url]);

  return [apiData, apiLoading, apiError];
};

export default UseApiNew;
