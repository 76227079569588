import React from "react";
import "./menuContent.css";
import Pagination from "../../../ui/pagination/pagination";
import MenuOrder from "./menuContentOrderBtns";

import { useDispatch } from "react-redux";
import {
  deleteItemFromCart,
  setItemInCart,
} from "../../../../redux/cart/reducer";

const MenuContent = ({
  productsToShow,
  countOfProducts,
  pageSize,
  currentPage,
  handlePageChange,
}) => {
  const dispatch = useDispatch();

  const handleOrder = (product) => {
    dispatch(setItemInCart(product));
  };

  const handleDelete = (id) => {
    dispatch(deleteItemFromCart(id));
  };

  return (
    <div className="container-fluid products__container">
      <div className="row">
        {productsToShow.map((product) => (
          <div
            className="col-sm-6 col-lg-4 col-xl-3 product__card"
            key={product._id}>
            <div className="product__inner">
              <div className="product__img-container">
                <img className="product__img" src={product.image} alt="img" />
              </div>

              <div className="product__header">
                <h5>{product.name}</h5>
                <h5 className="product__price">{product.price + " грн"}</h5>
                <h6>{product.portion}</h6>
              </div>

              <div className="product__about">
                <span className="product__ingredients">
                  {product.ingredients.join(", ")}
                </span>
                <span className="product__weight">{product.weight}</span>
              </div>

              <MenuOrder
                product={product}
                handleOrder={handleOrder}
                handleDelete={handleDelete}
              />
            </div>
          </div>
        ))}
        <Pagination
          itemsCount={countOfProducts}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default MenuContent;
