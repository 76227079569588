import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    itemsInCart: [],
  },
  reducers: {
    setItemInCart: (state, action) => {
      state.itemsInCart.push(action.payload);
    },
    deleteItemFromCart: (state, action) => {
      state.itemsInCart = state.itemsInCart.filter(
        (product) => product._id !== action.payload
      );
    },
    quantityIncrement: (state, action) => {
      const index = state.itemsInCart.findIndex(
        (product) => product._id === action.payload
      );
      let product = state.itemsInCart[index];
      product.quantity += 1;
      product.totalPrice = product.price * product.quantity;
    },
    quantityDecrement: (state, action) => {
      const index = state.itemsInCart.findIndex(
        (product) => product._id === action.payload
      );
      let product = state.itemsInCart[index];
      if (product.quantity === 1) return;
      product.quantity -= 1;
      product.totalPrice = product.price * product.quantity;
    },
    clearCart: (state) => {
      state.itemsInCart = [];
    },
  },
});

export const {
  setItemInCart,
  deleteItemFromCart,
  quantityIncrement,
  quantityDecrement,
  clearCart,
} = cartSlice.actions;
export default cartSlice.reducer;
