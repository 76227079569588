import React from "react";
import { useDispatch } from "react-redux";
import { deleteItemFromCart } from "../../../redux/cart/reducer";
import "./cart.css";
import CartCount from "./cartCount";
import { Link } from "react-router-dom";

export const Cart = ({ items: products, active, setActive, totalPrice }) => {
  const dispatch = useDispatch();

  const handleDelete = (id) => {
    dispatch(deleteItemFromCart(id));
  };

  return (
    <>
      <div className={active ? "eclipce active" : "eclipce"}></div>
      <div
        className={active ? "cart__sidebar active" : "cart__sidebar"}
        onClick={() => setActive(false)}>
        <div className="cart__inner" onClick={(e) => e.stopPropagation()}>
          <div className="cart__header">
            Кошик
            <div className="cart__close" onClick={() => setActive(false)}>
              <i className="bi bi-x"></i>
            </div>
          </div>
          <ul className="cart__products-list">
            {products.length === 0 ? (
              <li className="cart__empty" key="empyCart">
                <span>Кошик пустий</span>
              </li>
            ) : (
              products.map((product) => (
                <li key={product._id} className="cart__item">
                  <div className="cartItem__img-container">
                    <img
                      className="cartItem__img"
                      src={product.image}
                      alt="img"
                    />
                  </div>
                  <div className="cartItem__info">
                    <div className="cartItem__name">
                      <h5>{product.name}</h5>
                    </div>

                    <div className="cartItem__weight">
                      <h5>{product.weight}</h5>
                    </div>

                    <CartCount product={product} />

                    <div className="cartItem__price">
                      <h5>{product.totalPrice + " грн"}</h5>
                    </div>

                    <div className="cartItem__delete">
                      <button
                        className="cartItem__delete-button"
                        onClick={() => handleDelete(product._id)}>
                        <i className="bi bi-trash3-fill"></i>
                      </button>
                    </div>
                  </div>
                </li>
              ))
            )}
          </ul>

          <div className="container-fluid cart__footer-container">
            <div className="cart__footer">
              <div className="cart__footer-block">
                <span className="lil-text">До сплати:</span>
                <span className="big-text">
                  {totalPrice} <sup>грн</sup>
                </span>
              </div>
              <div className="cart__footer-block">
                {products.length === 0 ? (
                  <button className="cart__footer-accept-disabled" disabled>
                    ОФОРМИТИ ЗАМОВЛЕННЯ
                  </button>
                ) : (
                  <Link to="/order" className="order-link">
                    <button
                      className="cart__footer-accept"
                      onClick={() => setActive(false)}>
                      ОФОРМИТИ ЗАМОВЛЕННЯ
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
