import React from "react";
import { useDispatch } from "react-redux";
import {
  quantityIncrement,
  quantityDecrement,
} from "../../../redux/cart/reducer";

const CartCount = ({ product }) => {
  const dispatch = useDispatch();

  const handleIncrement = (id) => {
    dispatch(quantityIncrement(id));
  };

  const handleDecrement = (id) => {
    dispatch(quantityDecrement(id));
  };

  return (
    <div className="cartItem__buttons">
      <button
        className="cartItem__button"
        onClick={() => handleDecrement(product._id)}>
        -
      </button>
      <span className="cartItem__button-amount">{product.quantity}</span>
      <button
        className="cartItem__button"
        onClick={() => handleIncrement(product._id)}>
        +
      </button>
    </div>
  );
};

export default CartCount;
