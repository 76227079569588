import cafePhoto1 from "../assets/images/aboutImages/gallery01.jpg";
import cafePhoto2 from "../assets/images/aboutImages/gallery02.jpg";
import cafePhoto3 from "../assets/images/aboutImages/gallery04.jpg";
import cafePhoto4 from "../assets/images/aboutImages/gallery05.jpg";
import cafePhoto5 from "../assets/images/aboutImages/gallery06.jpg";
import cafePhoto6 from "../assets/images/aboutImages/gallery07.jpg";
import cafePhoto7 from "../assets/images/aboutImages/gallery08.jpg";

export const galleryImages = [
  cafePhoto1,
  cafePhoto2,
  cafePhoto3,
  cafePhoto4,
  cafePhoto5,
  cafePhoto6,
  cafePhoto7,
];
