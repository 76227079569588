import React from "react";
import "./pagination.css";

const Pagination = ({ itemsCount, pageSize, currentPage, onPageChange }) => {
  const pageCount = Math.ceil(itemsCount / pageSize);
  if (pageCount === 1) return null;

  function numberRange(start, end) {
    return new Array(end - start).fill().map((d, i) => i + start);
  }

  const pages = numberRange(1, pageCount + 1);

  return (
    <nav className="container pagination__container">
      <ul className="pagination">
        {pages.map((page) => (
          <li className="page-item" key={"page_" + page}>
            <a className="anchor" href="#productsMenu">
              <button
                className={
                  "page-link" + (page === currentPage ? " active-page" : "")
                }
                onClick={() => onPageChange(page)}>
                {page}
              </button>
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;
