import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  quantityIncrement,
  quantityDecrement,
} from "../../../../redux/cart/reducer";

const MenuOrder = ({ product: data, handleOrder, handleDelete }) => {
  const [product, setProduct] = useState(data);

  const items = useSelector((state) => state.cart.itemsInCart);
  const isItemInCart = items.some((item) => item._id === product._id);

  const dispatch = useDispatch();

  const handleIncrement = (quantity, targetedProduct) => {
    if (isItemInCart) {
      dispatch(quantityIncrement(targetedProduct._id));
      setProduct((product) => {
        return {
          ...product,
          quantity: quantity + 1,
          totalPrice: product.price * (quantity + 1),
        };
      });
    } else {
      setProduct((product) => {
        return {
          ...product,
          quantity: quantity + 1,
          totalPrice: product.price * (quantity + 1),
        };
      });
    }
  };

  const handleDecrement = (quantity, targetedProduct) => {
    if (quantity === 1) return;

    if (isItemInCart) {
      dispatch(quantityDecrement(targetedProduct._id));
      setProduct((product) => {
        return {
          ...product,
          quantity: quantity - 1,
          totalPrice: product.price * (quantity - 1),
        };
      });
    } else {
      setProduct((product) => {
        return {
          ...product,
          quantity: quantity - 1,
          totalPrice: product.price * (quantity - 1),
        };
      });
    }
  };

  return (
    <div className="product__buttons">
      <div className="product__buttons-amount">
        <button
          className="product__buttons-amount_btn"
          onClick={() => handleDecrement(product.quantity, product)}>
          -
        </button>
        <span className="product__buttons-amount_number">
          {product.quantity}
        </span>
        <button
          className="product__buttons-amount_btn"
          onClick={() => handleIncrement(product.quantity, product)}>
          +
        </button>
      </div>
      <button
        className={
          isItemInCart ? "product__buttons-cancel" : "product__buttons-order"
        }
        onClick={
          isItemInCart
            ? () => handleDelete(product._id)
            : () => handleOrder(product)
        }>
        {isItemInCart ? "Скасувати" : "Замовити"}
        <span
          className={
            !isItemInCart ? "product__added" : "product__added fadeOutDown"
          }>
          Добавлено у кошик
        </span>
      </button>
    </div>
  );
};

export default MenuOrder;
