import React from "react";
import "./orderLoading.css";

const OrderLoading = ({ isLoading }) => {
  return (
    <div
      className={
        !isLoading ? "loading__container" : "loading__container setted"
      }>
      <div className="loading__block">
        <div className="loader__container">
          <div className="box11"></div>
          <div className="box22"></div>
          <div className="box33"></div>
        </div>
      </div>
    </div>
  );
};

export default OrderLoading;
