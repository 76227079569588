import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./order.css";
import { deleteItemFromCart, clearCart } from "../../../redux/cart/reducer";
import OrderCart from "./orderCart";
import OrderForm from "./orderFrom";
import OrderAccepted from "../../ui/orderAccepted";
import useInput from "../../../hooks/useInput";
import OrderLoading from "../../ui/orderLoading";
import OrderError from "../../ui/orderError";

export const Order = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.cart.itemsInCart);
  const handleDelete = (id) => {
    dispatch(deleteItemFromCart(id));
  };
  const handleClear = () => {
    dispatch(clearCart());
  };
  const totalPrice = products.reduce(
    (acc, item) => (acc += item.totalPrice),
    0
  );

  const name = useInput("", { isEmpty: true, minLength: 2 });
  const telephone = useInput("", {
    minLength: 10,
    isTelephone: true,
  });
  const street = useInput("", { isEmpty: true });
  const house = useInput("", { isEmpty: true });
  const entrance = useInput("");
  const flat = useInput("");
  const floor = useInput("");
  const freeAdress = useInput("", { isEmpty: true });
  const comment = useInput("");
  const time = useInput("", { isEmpty: true });
  const persons = useInput("", { isEmpty: true });

  const [orderType, setOrderType] = useState("delivery");
  const handleOrderTypeChange = (orderType) => {
    setOrderType(orderType);
  };

  const [orderAccepted, setOrderAccepted] = useState(false);
  const handleCloseWindow = () => {
    setOrderAccepted(false);
    handleClear();
  };

  const [adressChoiceStandart, setAdressChoice] = useState(true);
  const isAdressStandard = (boolian) => {
    setAdressChoice(boolian);
  };

  const TOKEN = "5654327400:AAG26h-nZRmr3mR1zjcgUrVXDT-pWCr5kco";
  const CHAT_ID = "-1001549471586";
  const URL_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;

  const forTelegram = products.map((product) => {
    return `<i>${product.name}</i>\n Кількість: ${product.quantity}\n Загальна вартість: ${product.totalPrice}\n\n`;
  });

  const [isLoading, setLoading] = useState(false); // ЗАГРУЗКА
  const [isError, setError] = useState(false); // відображення помилки відправки замовлення

  const sendToPraha = () => {
    let massage = `<b>Замовлення зі сайту</b>\n`;
    massage += `Ім'я: ${name.value}\n`;
    massage += `Телефон: ${telephone.value}\n\n`;

    switch (orderType) {
      case "delivery":
        massage += `<b>ДОСТАВКА</b>\n`;
        if (adressChoiceStandart) {
          massage += `Вулиця: ${street.value}\n`;
          massage += `Будинок: ${house.value}\n`;
          if (entrance.value) {
            massage += `Під'їзд: ${entrance.value}\n`;
          }
          if (flat.value) {
            massage += `Квартира: ${flat.value}\n`;
          }
          if (floor.value) {
            massage += `Поверх: ${floor.value}\n`;
          }
        } else {
          massage += `Вільна адреса: ${freeAdress.value}\n`;
        }
        break;
      case "selfPickup":
        massage += `<b>САМОВИВІЗ</b>\n`;
        break;
      case "inCafe":
        massage += `<b>У ЗАКЛАДІ</b>\n`;
        massage += `Час: ${time.value}\n`;
        massage += `Кількість осіб: ${persons.value}\n`;
        break;
      default:
    }
    massage += `\n<b>Замовлення:</b>\n`;
    for (let index = 0; index < forTelegram.length; index++) {
      const element = forTelegram[index];
      massage += `${element}`;
    }
    if (comment.value) {
      massage += `<b>Коментар:</b> ${comment.value}\n`;
    }
    massage += `\n<b>ЗАГАЛЬНА СУМА: ${totalPrice} грн</b>`;
    massage += `\n<i></i>`;

    const sendOrder = async () => {
      const settings = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chat_id: CHAT_ID,
          parse_mode: "html",
          text: massage,
        }),
      };
      try {
        setLoading(true);
        const fetchResponse = await fetch(URL_API, settings);
        const data = await fetchResponse.json();
        if (data.ok) {
          setLoading(false);
          setOrderAccepted(true);
          // return data;
        } else {
          setLoading(false);
          setError(true);
        }
      } catch (e) {
        setLoading(false);
        setError(true);
        return e;
      }
    };
    sendOrder();
  };

  const acсessToOrder = () => {
    if (name.inputValid && telephone.inputValid) {
      if (orderType === "selfPickup") {
        return true;
      } else if (orderType === "delivery") {
        if (adressChoiceStandart && street.inputValid && house.inputValid) {
          return true;
        } else if (!adressChoiceStandart && freeAdress.inputValid) {
          return true;
        } else {
          return false;
        }
      } else if (orderType === "inCafe") {
        if (time.inputValid && persons.inputValid) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  };

  // const makeFormDirty = () => {
  //   if (name.inputValid) {
  //     name.setDirty(true);
  //   }
  // };

  return (
    <div className="container-fluid order__container">
      <OrderLoading isLoading={isLoading} />
      <OrderAccepted
        orderAccepted={orderAccepted}
        handleCloseWindow={handleCloseWindow}
      />
      <OrderError isError={isError} setError={setError} />
      <OrderForm
        {...{
          name,
          telephone,
          street,
          house,
          entrance,
          flat,
          floor,
          freeAdress,
          comment,
          isAdressStandard,
          handleOrderTypeChange,
          time,
          persons,
        }}
      />

      <OrderCart products={products} handleDelete={handleDelete} />

      <div className={"container-fluid   orderCart__footer-container"}>
        <div className="orderCart__footer">
          <div className="orderCart__footer-block">
            <span className="lil-text">До сплати:</span>
            <span className="big-text">
              {totalPrice} <sup>грн</sup>
            </span>
          </div>
          <div className="orderCart__footer-block">
            {products.length === 0 ? (
              <div className="orderCart-link">
                <button className="orderCart__footer-accept-disabled" disabled>
                  ПІДТВЕРДИТИ ЗАМОВЛЕННЯ
                </button>
              </div>
            ) : (
              <div className="orderCart-link">
                <button
                  className="orderCart__footer-accept"
                  disabled={!acсessToOrder()}
                  onClick={sendToPraha}>
                  ПІДТВЕРДИТИ ЗАМОВЛЕННЯ
                  {!acсessToOrder() && (
                    <span>Поля зі знаком * обов'язкові для заповнення</span>
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
