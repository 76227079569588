import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Cart } from "../../mainBlocks/cart";

import "./navCart.css";

const NavCart = () => {
  const [cartActive, setCartActive] = useState(false);

  const items = useSelector((state) => state.cart.itemsInCart);
  const totalPrice = items.reduce((acc, item) => (acc += item.totalPrice), 0);
  return (
    <>
      <button
        className="navCart__button"
        onClick={() => setCartActive(!cartActive)}>
        <p className="navCart__text">
          <i
            className={
              !cartActive ? "bi bi-basket" : "bi bi-basket active-basket"
            }></i>{" "}
          {totalPrice} грн
        </p>
      </button>
      <Cart
        items={items}
        active={cartActive}
        setActive={setCartActive}
        totalPrice={totalPrice}
      />
    </>
  );
};

export default NavCart;
