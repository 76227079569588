import React from "react";
import "./reviews.css";

export const Reviews = () => {
  return (
    <div className="container-fluid review__container">
      <h2>Відгуки</h2>
      <div className="review__inner row">
        <div className="col-sm-6 col-lg-3 col-xl-3 review__block">
          <span>
            «Приємно була вражена, що у такому невеликому містечку є достойний
            заклад, як у Львові або Києві від зазначених міст відрізняється
            хіба, що цінами, все значно нижче. Дуже смачна кухня, рекомендую
            «рульку» та «ребра», смачні коктелі і на літо є холодна кава.
            Рекомендую містянам Ходорова, а також гостям міста. Приємний
            спрацьований персонал.»
          </span>
          <h5>Оксана Костюк</h5>
        </div>
        <div className="col-sm-6 col-lg-3 col-xl-3 review__block">
          <span>
            «Завжди рада сюди приїхати, кухня дуже смачна, обслуговування варте
            гарних чайових, дуже приємно повечеряти в такій обстановці, раджу
            всім відвідати ПРАГУ.»
          </span>
          <h5>oksana teplishin</h5>
        </div>
        <div className="col-sm-6 col-lg-3 col-xl-3 review__block">
          <span>
            «Чудове атмосферне кафе з клієнт орієнтованим персоналом. Приємний
            інтер’єр, уважний бармен. Бюджетні ціни. Приємно здивував раритетний
            патіфон, було б цікаво його почути) Побажання від себе - розширити
            меню страв. Так тримати!»
          </span>
          <h5>o H</h5>
        </div>
        <div className="col-sm-6 col-lg-3 col-xl-3 review__block">
          <span>
            «Кафе дуже круте, кухня суперова. Дуже смачно і ситно можна поїсти.
            Часто замовляю доставку, ніколи претензій не було!»
          </span>
          <h5>Nazar Guber</h5>
        </div>
      </div>
    </div>
  );
};
