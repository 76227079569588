import { useState, useEffect } from "react";

const useValidation = (value, validations) => {
  const [isEmpty, setEmptyError] = useState(false);
  const [minLengthError, setMinLengthError] = useState(false);
  const [isTelephone, setTelephone] = useState(false);
  const [inputValid, setInputValid] = useState(false);

  useEffect(() => {
    for (const validation in validations) {
      switch (validation) {
        case "isEmpty":
          value
            ? setEmptyError(false)
            : setEmptyError("Поле не може бути пустим");
          break;
        case "minLength":
          value.length < validations[validation]
            ? setMinLengthError(
                `Мінімальна кількість символів ${validations[validation]}`
              )
            : setMinLengthError(false);
          break;
        case "isTelephone":
          const telephoneRegEx =
            /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;
          if (telephoneRegEx.test(value)) {
            setTelephone(false);
          } else {
            setTelephone(
              "Правильний телефонний номер обов'язковий для замовлення"
            );
          }
          break;
        default:
      }
    }
  }, [value, validations]);

  useEffect(() => {
    if (isEmpty || minLengthError || isTelephone) {
      setInputValid(false);
    } else {
      setInputValid(true);
    }
  }, [isEmpty, minLengthError, isTelephone]);

  return { isEmpty, minLengthError, isTelephone, inputValid };
};

export default useValidation;
