import React from "react";
import { About } from "../components/mainBlocks";

const AboutPage = () => {
  return (
    <div className="main" style={{ paddingTop: "80px" }}>
      <About showWithBigWidth showWithSmallWidth={true} />
    </div>
  );
};

export default AboutPage;
