export default (
  <svg
    className="bike__icon"
    width="58"
    height="58"
    viewBox="0 0 98 98"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M80.6153 97.9998C74.5142 97.9998 69.5508 93.0348 69.5508 86.9353H91.6798C91.6798 93.0348 86.7164 97.9998 80.6153 97.9998Z"
      fill="#FFAF00"
    />
    <path
      d="M30.0333 97.9999C23.9322 97.9999 18.9688 93.0349 18.9688 86.9354C18.9688 80.8358 23.9322 75.8708 30.0333 75.8708C36.1343 75.8708 41.0978 80.8358 41.0978 86.9354C41.0978 93.0349 36.1343 97.9999 30.0333 97.9999ZM30.0333 83.7741C28.2898 83.7741 26.872 85.1919 26.872 86.9354C26.872 88.6789 28.2898 90.0967 30.0333 90.0967C31.7768 90.0967 33.1946 88.6789 33.1946 86.9354C33.1946 85.1919 31.7768 83.7741 30.0333 83.7741Z"
      fill="#FFAF00"
    />
    <path
      d="M74.2922 41.0967H64.8083C62.1943 41.0967 60.0664 38.9688 60.0664 36.3547C60.0664 33.7406 62.1943 31.6128 64.8083 31.6128H74.2922V41.0967Z"
      fill="#FFAF00"
    />
    <path
      d="M34.7752 20.5483H18.9688V1.58057H34.7752V20.5483Z"
      fill="#FFAF00"
    />
    <path
      d="M34.7752 45.8386H18.9688V36.3547H34.7752V45.8386Z"
      fill="#FFAF00"
    />
    <path
      d="M30.031 82.1936C27.4169 82.1936 25.2891 84.3215 25.2891 86.9355C25.2891 89.5496 27.4169 91.6775 30.031 91.6775C32.6451 91.6775 34.7729 89.5496 34.7729 86.9355C34.7729 84.3215 32.6451 82.1936 30.031 82.1936ZM30.031 88.5162C29.1604 88.5162 28.4504 87.8077 28.4504 86.9355C28.4504 86.0634 29.1604 85.3549 30.031 85.3549C30.9016 85.3549 31.6116 86.0634 31.6116 86.9355C31.6116 87.8077 30.9016 88.5162 30.031 88.5162Z"
      fill="#2D2C2C"
    />
    <path
      d="M86.6098 70.6359L83.8012 46.7633C84.7636 47.1824 85.821 47.4194 86.9355 47.4194H91.6774C93.4209 47.4194 94.8387 46.0016 94.8387 44.2581V34.7742C94.8387 33.0307 93.4209 31.6129 91.6774 31.6129H72.7097V71.4532C70.5934 72.5376 68.7372 74.0549 67.233 75.871H59.6755L54.256 56.9032H60.0645V55.3226C60.0645 49.7602 55.9346 45.1557 50.5806 44.3846V0H3.16129V44.549C1.32595 45.2035 0 46.9424 0 49V52.1613C0 54.7754 2.12785 56.9032 4.74194 56.9032H10.5821C6.10262 60.0522 3.16129 65.2487 3.16129 71.129V88.5161H15.8065V86.9355C15.8065 79.0909 22.1892 72.7097 30.0323 72.7097C37.8753 72.7097 44.2581 79.0909 44.2581 86.9355V88.5161H98V86.9355C98 79.4575 93.2473 73.0871 86.6098 70.6359V70.6359ZM91.6774 34.7742L91.679 44.2581H86.9355C85.4706 44.2581 84.174 43.575 83.3034 42.5269L83.2988 42.4922L83.2764 42.4953C82.6096 41.6787 82.1936 40.6491 82.1936 39.5161C82.1936 36.902 84.3214 34.7742 86.9355 34.7742H91.6774ZM75.871 34.7742H80.6538C79.6505 36.0986 79.0323 37.7302 79.0323 39.5161C79.0323 41.0559 79.4923 42.4829 80.2571 43.6985L83.325 69.7807C82.4398 69.6418 81.5375 69.5484 80.6129 69.5484C78.969 69.5484 77.3791 69.7838 75.871 70.2121V34.7742ZM6.32258 44.2581V12.6452H47.4194V44.2581H6.32258ZM47.4194 3.16129V9.48387H6.32258V3.16129H47.4194ZM4.74194 53.7419C3.87135 53.7419 3.16129 53.0334 3.16129 52.1613V49C3.16129 48.1279 3.87135 47.4194 4.74194 47.4194H49C52.8173 47.4194 56.0103 50.1399 56.7435 53.7419H4.74194ZM47.3484 85.3548C46.5472 76.5046 39.0878 69.5484 30.0323 69.5484C20.9767 69.5484 13.5173 76.5046 12.7162 85.3548H6.32258V71.129C6.32258 63.2845 12.7054 56.9032 20.5484 56.9032H50.9696L57.2922 79.0323H65.1352C64.1458 80.961 63.5021 83.0942 63.2984 85.3548H47.3484ZM66.4743 85.3548C67.2631 78.2512 73.3024 72.7097 80.6129 72.7097C87.9234 72.7097 93.9627 78.2512 94.7515 85.3548H66.4743Z"
      fill="#2D2C2C"
    />
    <path
      d="M25.2891 15.8064H28.4504V20.5483H25.2891V15.8064Z"
      fill="#2D2C2C"
    />
    <path d="M25.2891 36.355H28.4504V41.0969H25.2891V36.355Z" fill="#2D2C2C" />
    <path
      d="M44.2578 60.0645H47.4191V63.2257H44.2578V60.0645Z"
      fill="#2D2C2C"
    />
    <path
      d="M37.9336 60.0645H41.0949V63.2257H37.9336V60.0645Z"
      fill="#2D2C2C"
    />
    <path
      d="M31.6133 60.0645H34.7746V63.2257H31.6133V60.0645Z"
      fill="#2D2C2C"
    />
  </svg>
);
