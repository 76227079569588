import React from "react";
import "./about.css";
import { galleryImages } from "../../../constants/forAbout";
import googlemap from "../../../assets/images/googlemap.png";
import { Reviews } from "../reviews";

export const About = ({ showWithBigWidth, showWithSmallWidth }) => {
  return (
    <div className="container-fluid about__container">
      <div className="row about__block ">
        {showWithBigWidth && showWithSmallWidth && (
          <div className="about__text-block col-sm-12 col-lg-6 col-xl-6">
            <h1 className="about__header">Про Нас</h1>
            <div className="h-divider">
              <div className="divider__shadow"></div>
            </div>

            <ul className="about__list">
              <h2 className="about__list-header">
                Атмосфера Праги у центрі міста Ходорів
              </h2>
              <li className="about__list-item">
                Тут вас зустрічає вигляд на Карлів міст, запах свинної чеської
                рульки та келих холодного пива «Kozel»
              </li>
              <h2 className="about__list-header">
                Praha - кафе з неймовірно смачними стравами
              </h2>
              <li className="about__list-item">
                Наша команда працює, щоб ви відчули пригоду куштуючи нові
                страви. Ця подорож принесе вам багато незабутніх гастрономічних
                вражень
              </li>
            </ul>
          </div>
        )}

        {/* Слайдер */}
        <div className="about__images-block col-sm-12 col-lg-6 col-xl-6">
          <div
            id="carousel"
            className="carousel  slide"
            data-bs-ride="carousel">
            <div className="carousel-inner">
              {galleryImages.map((image, index) => (
                <div
                  className={"carousel-item " + (index === 0 ? "active" : "")}
                  loading="lazy"
                  key={index}
                  data-bs-interval="10000000000">
                  <img
                    src={image}
                    className="h-75 w-100 about__images"
                    alt="Gallery"
                  />
                </div>
              ))}
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carousel"
              data-bs-slide="prev">
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carousel"
              data-bs-slide="next">
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>

      <Reviews />

      {/* Google Maps */}

      <div className="google-map__container">
        <a href="https://www.google.com/maps/place/cafe_praga_khodoriv/@49.4076416,24.312235,18.5z/data=!4m5!3m4!1s0x473a7db46a300147:0xbc3299170903eefd!8m2!3d49.4075976!4d24.312757?hl=ua">
          <img className="google-map" src={googlemap} alt="google_map" />
        </a>
        <span className="google-map__text">
          <i className="bi bi-arrow-up-circle-fill"></i> Ми у Google Maps{" "}
          <i className="bi bi-arrow-up-circle-fill"></i>
        </span>
      </div>
    </div>
  );
};
