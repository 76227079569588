import React from "react";
import { Order } from "../components/mainBlocks";

const OrderPage = () => {
  return (
    <div className="main" style={{ paddingTop: "90px" }}>
      <Order />
    </div>
  );
};

export default OrderPage;
