import React from "react";
import "./delivery.css";
import deliveryImage from "../../../assets/images/bike2.png";
import bikeIcon from "../../../assets/images/svg/bike-1.js";

export const Delivery = () => {
  return (
    <div className="container-fluid delivery__container">
      <div className="row delivery__block">
        <div className="delivery__image-block col-sm-12 col-lg-6 col-xl-6">
          <img
            className="delivery__img"
            src={deliveryImage}
            alt="Delivery_Image"
          />
        </div>
        <div className="delivery__info-block col-sm-12 col-lg-6 col-xl-6">
          <div className="delivery__info-inner">
            <h1 className="delivery__header">Доставка і оплата</h1>
            <div className="h-divider">
              <div className="divider__shadow"></div>
            </div>
            <span className="delivery__text-block">
              <div className="delivery__icon">{bikeIcon}</div>
              <p className="delivery__info">
                <b>
                  Доставка здійснюється службою таксі та по тарифам служби таксі{" "}
                </b>
              </p>
            </span>
            <span className="delivery__text-block">
              <div className="delivery__icon">{bikeIcon}</div>
              <p className="delivery__info">
                <b>Доставка по місту Ходорів</b> - вартість залежить від адреси
                доставки (на межі міста - до 80грн) <br />
                <i> Тариф - 60-80 грн</i>
              </p>
            </span>
            <span className="delivery__text-block">
              <div className="delivery__icon">{bikeIcon}</div>
              <p className="delivery__info">
                <b>Доставка у найближчі села: Жирова, Вовчатичі, Отиневичі</b> -
                вартість залежить від адреси доставки (протилежний кінець від
                Ходорова - до 150грн) <br />
                <i> Тариф - 100-150 грн</i>
              </p>
            </span>
            <span className="delivery__text-block">
              <div className="delivery__icon">{bikeIcon}</div>
              <p className="delivery__info">
                <b>Години доставки: 10:00-21:00</b>
              </p>
            </span>
            <span
              className="delivery__text-block"
              style={{ marginTop: "10px" }}>
              <div className="delivery__icon">{bikeIcon}</div>
              <p className="delivery__info">
                При <b>замовленні через сайт</b> - ми до вас зателефонуємо для
                підтвердження замовлення
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
