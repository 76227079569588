import React from "react";
import {
  About,
  Banner,
  Benefits,
  Delivery,
  Menu,
} from "../components/mainBlocks";

const Home = () => {
  const isScreenSmall = window.screen.availWidth >= 555;
  return (
    <>
      <Banner />
      <Menu />
      <Delivery />
      <Benefits />
      <About showWithBigWidth showWithSmallWidth={isScreenSmall} />
    </>
  );
};

export default Home;
