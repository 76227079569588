import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./nav.css";

import logoL from "../../assets/images/logoL.png";
import NavCart from "./navCart/navCart";

const NavBar = () => {
  const [menuActive, setMenuActive] = useState(false);

  return (
    <div className="header fixed-top">
      <div className="header__container">
        <Link className="header__logo" to="/">
          <img className="logo__img" src={logoL} alt="logo" />
        </Link>
        <div className="header__menu menu">
          <div
            onClick={() => setMenuActive(!menuActive)}
            className={!menuActive ? "menu__icon " : "menu__icon _active"}>
            <span></span>
          </div>
          <nav className={!menuActive ? "menu__body " : "menu__body _active"}>
            <ul className="menu__list" onClick={() => setMenuActive(false)}>
              <li>
                <Link to="/" className="nav-link">
                  Головна
                </Link>
              </li>
              <li>
                <Link to="/menu" className="nav-link">
                  Меню
                </Link>
              </li>
              <li>
                <Link to="/delivery" className="nav-link">
                  Доставка
                </Link>
              </li>
              <li>
                <Link to="/about" className="nav-link">
                  Про нас
                </Link>
              </li>
            </ul>
          </nav>
          <ul className="menu__info">
            <li>
              <a type="tel" className="telephone" href="tel:+380984354434">
                <span className="phone__icon">
                  <i className="bi bi-telephone"></i>
                </span>{" "}
                <span className="phone__number">+380 (98) 435 44 34</span>
              </a>
            </li>
            <li>
              <NavCart />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
