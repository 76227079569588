import React, { useState } from "react";
import "./orderForm.css";
import PropTypes from "prop-types";

const OrderForm = ({
  name,
  telephone,
  street,
  house,
  entrance,
  flat,
  floor,
  freeAdress,
  comment,
  isAdressStandard,
  handleOrderTypeChange,
  time,
  persons,
}) => {
  const [deliveryOrderType, setDeliveryOrderType] = useState(true);
  const [pickupOrderType, setPickupOrderType] = useState(false);
  const [inCafeOrderType, setInCafeOrderType] = useState(false);

  const handleDeliveryOrderType = () => {
    deliveryOrderType
      ? setDeliveryOrderType(true)
      : setDeliveryOrderType(!deliveryOrderType);
    setPickupOrderType(false);
    setInCafeOrderType(false);
    handleOrderTypeChange("delivery");
  };
  const handlePickupOrderType = () => {
    pickupOrderType
      ? setPickupOrderType(pickupOrderType)
      : setPickupOrderType(!pickupOrderType);
    setDeliveryOrderType(false);
    setInCafeOrderType(false);
    handleOrderTypeChange("selfPickup");
  };
  const handleInCafeOrderType = () => {
    inCafeOrderType
      ? setInCafeOrderType(inCafeOrderType)
      : setInCafeOrderType(!inCafeOrderType);
    setPickupOrderType(false);
    setDeliveryOrderType(false);
    handleOrderTypeChange("inCafe");
  };

  const [checkeStandartAdress, setCheckStandartAdress] = useState(true);
  const [checkeFreeAdress, setCheckFreeAndress] = useState(false);
  const handleCheckStandart = () => {
    checkeStandartAdress
      ? setCheckStandartAdress(true)
      : setCheckStandartAdress(!checkeStandartAdress);
    setCheckFreeAndress(false);
    isAdressStandard(true);
  };
  const handleCheckFree = () => {
    checkeFreeAdress
      ? setCheckFreeAndress(checkeFreeAdress)
      : setCheckFreeAndress(!checkeFreeAdress);
    setCheckStandartAdress(false);
    isAdressStandard(false);
  };

  return (
    <div className="orderForms__inner">
      <form className="order__form">
        {/* Доставка/самовивіз - вибір */}
        <h1 className="forms__title1"> Отримання замовлення</h1>
        <div className="checkbox__container">
          <div
            className={deliveryOrderType ? "checkbox box-active" : "checkbox"}
            onClick={handleDeliveryOrderType}>
            <input
              type="radio"
              name="orderType"
              value="delivery"
              className="checkbox__input"
              id="orderType-1"
              defaultChecked
            />
            <label className="checkbox__label-one" htmlFor="orderType-1">
              Доставка
            </label>
          </div>
          <div
            className={!pickupOrderType ? "checkbox" : "checkbox box-active"}
            onClick={handlePickupOrderType}>
            <input
              type="radio"
              name="orderType"
              value="selfPickup"
              className="checkbox__input"
              id="orderType-2"
            />
            <label className="checkbox__label-two" htmlFor="orderType-2">
              Самовивіз
            </label>
          </div>
          <div
            className={!inCafeOrderType ? "checkbox" : "checkbox box-active"}
            onClick={handleInCafeOrderType}>
            <input
              type="radio"
              name="orderType"
              value="inCafe"
              className="checkbox__input"
              id="orderType-3"
            />
            <label className="checkbox__label-three" htmlFor="orderType-3">
              У закладі
            </label>
          </div>
        </div>

        {/* Контактні дані */}
        <h1 className="forms__title2"> Контактні дані</h1>
        {/* ---Name input  */}
        <div className="forms__group">
          <input
            className="forms__input"
            value={name.value}
            type="text"
            placeholder=" "
            onChange={name.onChange}
            onBlur={(e) => name.onBlur(e)}
          />
          {name.isDirty && <div className="errTxt">{name.isEmpty}</div>}
          {name.isDirty && <div className="errTxt">{name.minLengthError}</div>}
          <label className="forms__label">*Ваше ім'я</label>
        </div>
        {/* ---telephone input  */}
        <div className="forms__group">
          <input
            className="forms__input"
            value={telephone.value}
            type="text"
            placeholder=" "
            onChange={telephone.onChange}
            onBlur={(e) => telephone.onBlur(e)}
          />
          {telephone.isDirty && (
            <div className="errTxt">{telephone.minLengthError}</div>
          )}
          {telephone.isDirty && (
            <div className="errTxt">{telephone.isTelephone}</div>
          )}
          <label className="forms__label">*Номер телефону</label>
        </div>

        {/* Вибір стандартної чи вільної адреси */}
        {deliveryOrderType && (
          <>
            <h1 className="forms__title3"> Інформація для доставки</h1>
            <div className="checkbox__container">
              <div
                className={
                  checkeStandartAdress ? "checkbox box-active" : "checkbox"
                }
                onClick={handleCheckStandart}>
                <input
                  type="radio"
                  name="checkbox"
                  value="standartAdress"
                  className="checkbox__input"
                  id="checkbox-1"
                  defaultChecked
                />
                <label className="checkbox__label-one" htmlFor="checkbox-1">
                  Стандартна адреса
                </label>
              </div>
              <div
                className={
                  !checkeFreeAdress ? "checkbox" : "checkbox box-active"
                }
                onClick={handleCheckFree}>
                <input
                  type="radio"
                  name="checkbox"
                  value="freeAdress"
                  className="checkbox__input"
                  id="checkbox-2"
                />
                <label className="checkbox__label-two" htmlFor="checkbox-2">
                  Вільна адреса
                </label>
              </div>
            </div>
            {/* Інформація для доставки  (поля стандартної адреси) */}
            <div className="forms__second-group" hidden={!checkeStandartAdress}>
              {/* ---street input  */}
              <div className="forms__group elem-1">
                <input
                  className="forms__input"
                  value={street.value}
                  type="text"
                  placeholder=" "
                  onChange={street.onChange}
                  onBlur={(e) => street.onBlur(e)}
                />
                {street.isDirty && street.isEmpty && (
                  <div className="errTxt">{street.isEmpty}</div>
                )}
                <label className="forms__label">*Вулиця</label>
              </div>
              {/* ---house input  */}
              <div className="forms__group  elem-2">
                <input
                  className="forms__input"
                  value={house.value}
                  type="text"
                  placeholder=" "
                  onChange={house.onChange}
                  onBlur={(e) => house.onBlur(e)}
                />
                {house.isDirty && <div className="errTxt">{house.isEmpty}</div>}
                <label className="forms__label">*Номер будинку</label>
              </div>
              {/* ---entrance input  */}
              <div className="forms__group  elem-3">
                <input
                  className="forms__input"
                  value={entrance.value}
                  type="text"
                  placeholder=" "
                  onChange={entrance.onChange}
                />
                <label className="forms__label">Під'їзд</label>
              </div>
              {/* ---flat input  */}
              <div className="forms__group elem-4">
                <input
                  className="forms__input"
                  value={flat.value}
                  type="text"
                  placeholder=" "
                  onChange={flat.onChange}
                />
                <label className="forms__label">Квартира</label>
              </div>
              {/* ---floor input  */}
              <div className="forms__group  elem-5">
                <input
                  className="forms__input"
                  value={floor.value}
                  type="text"
                  placeholder=" "
                  onChange={floor.onChange}
                />
                <label className="forms__label">Поверх</label>
              </div>
            </div>
            {/* Вільна адреса */}
            {/* ---freeAdress input  */}
            <div className="forms__group" hidden={!checkeFreeAdress}>
              <div className="forms__group">
                <input
                  className="forms__input"
                  value={freeAdress.value}
                  type="text"
                  placeholder=" "
                  onChange={freeAdress.onChange}
                  minLength="5"
                  onBlur={(e) => freeAdress.onBlur(e)}
                />
                {freeAdress.isDirty && (
                  <div className="errTxt">{freeAdress.isEmpty}</div>
                )}
                <label className="forms__label">*Вільна адреса</label>
              </div>
            </div>
          </>
        )}

        {/* Година для замовлення у закладі */}
        {inCafeOrderType && (
          <>
            {/* ---time input  */}
            <h1 className="forms__title3"> Час та кількість осіб</h1>
            <div className="forms__group">
              <input
                className="forms__input"
                value={time.value}
                type="text"
                placeholder=" "
                onChange={time.onChange}
                onBlur={(e) => time.onBlur(e)}
              />
              {time.isDirty && time.isEmpty && (
                <div className="errTxt">{time.isEmpty}</div>
              )}
              <label className="forms__label">
                *На котру годину вас очікувати?
              </label>
            </div>
            <div className="forms__group">
              <input
                className="forms__input"
                value={persons.value}
                type="text"
                placeholder=" "
                onChange={persons.onChange}
                onBlur={(e) => persons.onBlur(e)}
              />
              {persons.isDirty && persons.isEmpty && (
                <div className="errTxt">{persons.isEmpty}</div>
              )}
              <label className="forms__label">*Кількість осіб</label>
            </div>
          </>
        )}

        {/* Додаткова інформація (коментар) */}
        {/* ---comment input  */}
        <h1 className="forms__title4"> Додаткова інформація</h1>
        <div className="forms__group">
          <input
            className="forms__input"
            value={comment.value}
            type="text"
            placeholder=" "
            onChange={comment.onChange}
          />
          <label className="forms__label">Ваш коментар</label>
        </div>
        <div className="forms__information">
          <h5 className="forms__info">
            **Після замовлення на сайті ми вам зателефонуємо для підтвердження
            заказу
          </h5>
          <h6 className="forms__info">
            **У вартість не входить одноразові контейнери для страв. Їх загальну
            вартість ми вам вкажемо при підтвердженні заказу. (~10 грн на
            страву){" "}
          </h6>
        </div>
      </form>
    </div>
  );
};

OrderForm.propTypes = {
  name: PropTypes.object.isRequired,
  telephone: PropTypes.object.isRequired,
  street: PropTypes.object.isRequired,
  house: PropTypes.object.isRequired,
  entrance: PropTypes.object.isRequired,
  flat: PropTypes.object.isRequired,
  floor: PropTypes.object.isRequired,
};

export default OrderForm;
