import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Provider } from "react-redux";
import { store } from "./redux";
import { Route, Switch, Redirect } from "react-router-dom";

import NavBar from "./components/navigation/navBar";
import NotFount from "./components/navigation/notFount";
import Home from "./layouts/homePage";
import MenuPage from "./layouts/menuPage";
import AboutPage from "./layouts/aboutPage";
import DeliveryPage from "./layouts/deliveryPage";
import { Footer } from "./components/mainBlocks";
import OrderPage from "./layouts/orderPage";
import ScrollToTop from "./components/utils/scrollToTop";

function App() {
  return (
    <Provider store={store}>
      <div className="wrapper">
        <ScrollToTop />
        <NavBar />

        <Switch>
          <Route exact path="/" render={() => <Home />} />
          <Route exact path="/menu" render={() => <MenuPage />} />
          <Route exact path="/delivery" render={() => <DeliveryPage />} />
          <Route exact path="/about" render={() => <AboutPage />} />
          <Route exact path="/order" render={() => <OrderPage />} />
          <Route path="/404" component={NotFount} />
          <Redirect to="/404" />
        </Switch>

        <Footer />
      </div>
    </Provider>
  );
}

export default App;
