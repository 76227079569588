import React from "react";
import "./benefits.css";

export const Benefits = () => {
  return (
    <div className="container-fluid benefits__block">
      <div className="row benefits__inner">
        <div className="benefits__item col-sm-6 col-lg-6 col-xl-3">
          <div className="benefits__item-icon ">
            <i className="_icon-fresh"></i>
          </div>
          <h1>Cвіжі продукти</h1>
          <span>
            Усі інгредієнти завжди свіжі та високої якості. Ваше здоров'я для
            нас дуже важливе
          </span>
        </div>

        <div className="benefits__item col-sm-6 col-lg-6 col-xl-3">
          <div className="benefits__item-icon">
            <i className="_icon-kitchen"></i>
          </div>
          <h1>Чистота на кухні</h1>
          <span>
            Ми як ніхто розуміємо, що усі продукти потребують абсолютної чистоти
            на кухні
          </span>
        </div>

        <div className="benefits__item col-sm-6 col-lg-6 col-xl-3">
          <div className="benefits__item-icon">
            <i className="_icon-largeportion"></i>
          </div>
          <h1>Великі порції</h1>
          <span>
            Ми віднайшли ідеальні порції та завжди дотримуємось грамажу
            вказаного на сайті
          </span>
        </div>

        <div className="benefits__item col-sm-6 col-lg-6 col-xl-3">
          <div className="benefits__item-icon">
            <i className="_icon-happyface"></i>
          </div>
          <h1>Неймовірний смак</h1>
          <span>
            Ми готуємо страви, які змусять Ваші рецептори шаленіти від насолоди
          </span>
        </div>
      </div>
    </div>
  );
};
