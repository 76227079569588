import React from "react";
import { Delivery } from "../components/mainBlocks";

const DeliveryPage = () => {
  return (
    <div className="main" style={{ paddingTop: "100px" }}>
      <Delivery />
    </div>
  );
};

export default DeliveryPage;
