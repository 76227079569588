import React, { useState, useEffect } from "react";
import UseApiNew from "../../../hooks/useApiNew";
import { useSwipeable } from "react-swipeable";
import Loader from "../../loader/loader";
import "./banner2.css";

export const Banner = () => {
  const [bannerInfo, bannerInfoLoading] = UseApiNew(
    "https://api.npoint.io/0741f3d46438f4d919fe/bannerInfo"
  );
  const [currentState, setCurrentState] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentState === 2) {
        setCurrentState(0);
      } else {
        setCurrentState(currentState + 1);
      }
    }, 10000);
    return () => clearTimeout(timer);
  });

  const goToNext = (currentState) => {
    setCurrentState(currentState);
  };

  const slideLeft = () => {
    if (currentState === 2) {
      setCurrentState(0);
    } else {
      setCurrentState(currentState + 1);
    }
  };
  const slideRight = () => {
    if (currentState === 0) {
      setCurrentState(2);
    } else {
      setCurrentState(currentState - 1);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => slideLeft(),
    onSwipedRight: () => slideRight(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const bgImage = bannerInfo[currentState]?.img;
  const bgH1 = bannerInfo[currentState]?._h1;
  const bgP = bannerInfo[currentState]?._p;
  const bgH2 = bannerInfo[currentState]?._h2;

  return (
    <>
      {bannerInfoLoading ? (
        <Loader />
      ) : (
        <div
          className="container-fluid banner__container"
          style={{ backgroundImage: `url(${bgImage})` }}
          {...handlers}>
          <div className="carousel-switches">
            {bannerInfo.map((bannerInfo, index) => (
              <span
                key={index}
                onClick={() => goToNext(index)}
                className={
                  index === currentState ? "active-switcher" : "switcher"
                }></span>
            ))}
          </div>
          <div className="banner">
            <div className={`banner__inner${currentState}`}>
              <h1 className="banner__text-big1">{bgH1}</h1>
              <p className="banner__text-small">{bgP}</p>
              <h1 className="banner__text-big2">{bgH2}</h1>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
