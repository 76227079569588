import React, { useState } from "react";
import "./select.css";

const Select = ({ categories, onCategorySelect, selectedCategory }) => {
  const [activeFirstCategory, setActiveFirstCategory] = useState(true);
  const [activeDropdown, setActiveDropdown] = useState(false);
  const [dropdownTitle, setDropdownTitle] = useState("Оберіть категорію");

  const handleSelect = () => {
    setActiveDropdown(!activeDropdown);
  };
  const handleTitleChange = (title) => {
    setDropdownTitle(title);
    setActiveFirstCategory(false);
    setActiveDropdown(!activeDropdown);
  };
  const handleFirstCategoryChange = (title) => {
    setDropdownTitle(title);
    setActiveFirstCategory(true);
    setActiveDropdown(!activeDropdown);
  };

  return (
    <div className="categories__select">
      <div className="select">
        <button className="select__button" onClick={handleSelect}>
          {dropdownTitle}
        </button>
        <ul
          className={
            activeDropdown
              ? "select__list select__list--visible"
              : "select__list"
          }>
          {categories.map((category) => (
            <li
              className="select__list-item"
              key={category._id + 1234}
              onClick={() => onCategorySelect(category)}>
              {category._id === undefined ? (
                <button
                  className={
                    activeFirstCategory
                      ? "btn select__list-button-active"
                      : "btn select__list-button"
                  }
                  onClick={() => handleFirstCategoryChange(category.name)}>
                  {category.name}
                </button>
              ) : (
                <button
                  className={
                    "btn select__list-button" +
                    (selectedCategory && category._id === selectedCategory._id
                      ? "-active"
                      : "")
                  }
                  onClick={() => handleTitleChange(category.name)}>
                  {category.name}
                </button>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Select;
