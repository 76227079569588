import React from "react";
import CartCount from "../../cart/cartCount";
import "./orderCart.css";

const OrderCart = ({ products, handleDelete }) => {
  return (
    <div className="orderCart__inner">
      <div className="orderCart__header">Ваш кошик</div>
      <ul className="orderCart__products-list">
        {products.length === 0 ? (
          <li className="orderCart__empty" key="empyorderCart">
            <span>Кошик пустий</span>
          </li>
        ) : (
          products.map((product) => (
            <li key={product._id} className="orderCart__item">
              <div className="orderCartItem__img-container">
                <img
                  className="orderCartItem__img"
                  src={product.image}
                  alt="img"
                />
              </div>
              <div className="orderCartItem__info">
                <div className="orderCartItem__name">
                  <h5>{product.name}</h5>
                </div>

                <div className="orderCartItem__weight">
                  <h5>{product.weight}</h5>
                </div>

                <CartCount product={product} />

                <div className="orderCartItem__price">
                  <h5>{product.totalPrice + " грн"}</h5>
                </div>

                <div className="orderCartItem__delete">
                  <button
                    className="orderCartItem__delete-button"
                    onClick={() => handleDelete(product._id)}>
                    <i className="bi bi-trash3-fill"></i>
                  </button>
                </div>
              </div>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default OrderCart;
