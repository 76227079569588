import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";
import { BrowserRouter } from "react-router-dom";
import "./css/normalize.css";
import "./index.css";
import "./app/assets/fonts/Intro.woff";
import "./app/assets/fonts/Intro.woff2";
import "./app/assets/fonts/myicons.eot";
import "./app/assets/fonts/myicons.svg";
import "./app/assets/fonts/myicons.ttf";
import "./app/assets/fonts/myicons.woff";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
